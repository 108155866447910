import CollapsibleButton from "components/CollapsibleButton"
import { Icon } from "components/Icon"
import { Title } from "components/Title"
import usePath from "hooks/usePath"
import useUser from "hooks/useUser"
import { t } from "locales"
import { Authors } from "modules/article/components/article-header/Authors"
import { DateTexts } from "modules/article/components/article-header/DateText"
import QRCode from "modules/article/components/article-header/QRCode"
import UpdatesPopover from "modules/article/components/article-header/UpdatesPopover"
import { useState } from "react"

import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid"
import Skeleton from "@mui/material/Skeleton"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import { IPage } from "data/contentData/api/content"
import { useFavorites } from "data/contentData/hooks/user.hooks"
import getContentIds from "lib/get-sources-ids"
import { toLocalDate } from "lib/string/date-to-locale-date-string"

type TabType = "qr" | "update"

interface IProps {
    page: IPage
}

export default function PageHeader({ page }: IProps) {
    const {
        title,
        updated,
        revised,
        published,
        revisionInterval,
        authors,
        ingress,
        byline,
        template,
        qrCodeUrl,
        updates
    } = page

    const { authorsContentId } = getContentIds(page)
    const url = usePath()
    const { favorite, toggle } = useFavorites(url)
    const { authenticated, user } = useUser()

    const [tabValue, setTabValue] = useState<TabType | null>(null)
    const handleClick = (value: TabType) => () => {
        setTabValue(prev => (prev === value ? null : value))
    }

    return (
        <StyledContainer container>
            <Grid
                item
                sx={{ pt: 1, fontWeight: 700 }}
                component={Title}
                id={title ?? "article-id"}
                xs={12}>
                {title ?? <Skeleton height={72} />}
            </Grid>
            {ingress && (
                <Grid
                    item
                    xs={12}>
                    <Typography
                        variant="body1"
                        sx={{ fontWeight: 700 }}>
                        {ingress}
                    </Typography>
                </Grid>
            )}

            {byline && (
                <Grid
                    item
                    xs={12}>
                    <Typography variant="body1">{byline}</Typography>
                </Grid>
            )}

            <DateTexts
                updated={updated}
                revised={revised}
                published={published}
                revisionInterval={revisionInterval}
            />
            <Authors
                authors={authors}
                authorsContentId={authorsContentId}
            />
            {updates && <UpdatesPopover updates={updates} />}
            <StyledProductAndPrinter
                item
                xs={12}>
                <Typography>
                    {`${t["on-print"]["print-from-product"]} ${t["product-name"]
                        }, ${toLocalDate(
                            new Date(),
                            process.env.NEXT_PUBLIC_LOCALE
                        )}`}
                    {user?.name &&
                        ` - ${t["on-print"]["printed-by"]} ${user.name}`}
                </Typography>
            </StyledProductAndPrinter>
            <Grid
                className="no-print"
                item
                component={Divider}
                xs={12}
            />
            <Grid
                item
                className="no-print"
                component={CollapsibleButton}
                icon={<Icon name="print" />}
                onClick={() => window.print()}>
                {t.print}
            </Grid>
            <Grid
                item
                className="no-print"
                component={CollapsibleButton}
                icon={
                    favorite ? (
                        <Icon name="favorite-added" />
                    ) : (
                        <Icon name="favorite-removed" />
                    )
                }
                onClick={() =>
                    title &&
                    toggle({ url, name: title, template: template?.name ?? "" })
                }
                disabled={!authenticated}>
                {!!favorite
                    ? t["favorite"]["remove-from-favorites"]
                    : t["favorite"]["add-to-favorites"]}
            </Grid>

            {qrCodeUrl && (
                <Grid
                    item
                    className="no-print"
                    component={CollapsibleButton}
                    icon={<Icon name="qr" />}
                    onClick={handleClick("qr")}>
                    {t["share-article"]}
                </Grid>
            )}

            {qrCodeUrl && (
                <Grid
                    item
                    xs={12}
                    sx={{ mt: 0 }}>
                    <QRCode
                        show={tabValue === "qr"}
                        url={qrCodeUrl}
                    />
                </Grid>
            )}

            <Grid
                className="no-print"
                item
                component={Divider}
                xs={12}
            />
        </StyledContainer>
    )
}

const StyledContainer = styled(Grid)(({ theme }) => {
    const { breakpoints, spacing } = theme
    return {
        justifyContent: "space-evenly",
        padding: spacing(0, 2, 0),
        gap: spacing(1),
        [breakpoints.up("sm")]: {
            "& > button": {
                margin: spacing(0, 1)
            },
            backgroundColor: "inherit",
            color: "inherit",
            justifyContent: "initial",
            padding: 0
        },
        "& > hr": {
            margin: spacing(1, 0)
        }
    }
})

const StyledProductAndPrinter = styled(Grid)(() => ({
    display: "none",
    "@media print": {
        display: "inherit"
    }
}))
