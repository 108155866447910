import { Icon } from "components/Icon"
import { t } from "locales"
import { useEffect, useState } from "react"

import Button, { ButtonProps } from "@mui/material/Button"

export interface CopyProps extends ButtonProps {
    value: string
}

export function Copy({ value, ...props }: CopyProps) {
    const [copied, setCopied] = useState(false)

    const handleCopy = async () => {
        if ("clipboard" in navigator) {
            await navigator.clipboard.writeText(value)
        }
        setCopied(true)
    }

    useEffect(() => {
        const handle = setTimeout(() => {
            if (copied) {
                setCopied(false)
            }
        }, 1000)
        return () => clearTimeout(handle)
    }, [copied])

    return (
        <Button
            color="primary"
            disabled={copied}
            onClick={handleCopy}
            size="small"
            startIcon={
                copied ? <Icon name="check" /> : <Icon name="fileCopy" />
            }
            variant="contained"
            {...props}>
            {copied ? t["copy"].true : t["copy"].false}
        </Button>
    )
}
